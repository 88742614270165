import React from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { ROUTES } from "./routes";
import Login from "pages/Login/Login";
import { ValidationCode } from "pages/Login/ValidationCode/ValidationCode";
import { ForgetPassword } from "pages/Login/ForgetPassword/ForgetPassword";
import { ResetPassword } from "pages/Login/ResetPassword/ResetPassword";
import Dashboard from "pages/Dashboard/Dashboard";
import Users from "pages/Users/Users";
import Transactions from "pages/Transactions/Transactions";
import Journal from "pages/Journal/Journal";
import Commissions from "pages/Commissions/Commissions";
import Operators from "pages/Operators/Operators";
import MyAccount from "pages/MyAccount/MyAccount";
import ManageCommission from "pages/ManageCommission/ManageCommission";
import ForgetPasswordEmail from "pages/Login/ForgetPassword/ForgetPasswordEmail";
import Country from "pages/Country/Country";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={ROUTES.login} element={<Login />} />
      <Route path={ROUTES.forgetPassword} element={<ForgetPassword />} />
      <Route path={ROUTES.validationCode} element={<ValidationCode />} />
      <Route
        path={ROUTES.emailCodeValidation}
        element={<ForgetPasswordEmail />}
      />
      <Route path={ROUTES.resetPassword} element={<ResetPassword />} />
      <Route element={<ProtectedRoute />}>
        <Route path={ROUTES.dashboard} element={<Dashboard />} />
        <Route path={ROUTES.users} element={<Users />} />
        <Route path={ROUTES.transactions} element={<Transactions />} />
        <Route path={ROUTES.journal} element={<Journal />} />
        <Route path={ROUTES.commissions} element={<Commissions />} />
        <Route path={ROUTES.manageCommision} element={<ManageCommission />} />
        <Route path={ROUTES.operators} element={<Operators />} />
        <Route path={ROUTES.myAccount} element={<MyAccount />} />
        <Route path={ROUTES.country} element={<Country />} />
      </Route>
      <Route path="*" element={<p>There's nothing here: 404!</p>} />
    </Routes>
  );
};

export default AppRoutes;
