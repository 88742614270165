import axios from "services/AxiosInstance";
import unauthorizedAxios from "services/AxiosInstance";
import { IUserPayload } from "utils/interfaces";

export const getCurrentUser = () => {
  return axios.get("api/users/me");
};

export const getUsersList = (page: number) => {
  return axios.get(`api/users?limit=12&page=${page}`);
};

export const createUser = (data: IUserPayload) => {
  return axios.post("api/users/", { ...data });
};

export const updateUser = (data: IUserPayload) => {
  if (!data?.id) {
    alert("Cannot update without ID");
  }
  return axios.put(`api/users/${data?.id}`, { ...data });
};

type ForgotPasswordPayload = {
  email: string;
};
export const forgotPassword = (data: ForgotPasswordPayload) => {
  return unauthorizedAxios.post("api/users/forgotPassword", data);
};

type ResetPasswordPayload = {
  newPassword: string;
  code: string;
};
export const resetPassword = (data: ResetPasswordPayload) => {
  return unauthorizedAxios.post("api/users/resetPassword", data);
};

const GetCurrentUser = () => {
  return axios.get("api/users/me");
};

const PutConnectedUserInfos = ({
  userId,
  payload,
}: {
  userId: number;
  payload: {
    firstname: string;
    lastname: string;
    phone: string;
    profilePicture?: string;
  };
}) => {
  return axios.put(`api/users/${userId}`, { ...payload });
};

const ForgotPassword = (data: ForgotPasswordPayload) => {
  return axios.post("api/users/forgotPassword", data);
};

const ResetPassword = (data: ResetPasswordPayload) => {
  return axios.post("api/users/resetPassword", data);
};

const DeleteUser = ({ userId }: { userId: number }) => {
  return axios.delete(`api/users/${userId}`);
};

export const UserService = {
  GetCurrentUser,
  PutConnectedUserInfos,
  ForgotPassword,
  ResetPassword,
  DeleteUser,
};
