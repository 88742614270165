import { AxiosError } from "axios";
import { UserContext } from "providers/UserProvider";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  UserService,
  createUser,
  getUsersList,
  updateUser,
} from "services/User/UserService";
import { IErrorResponse, IUserItem, IUserPayload } from "utils/interfaces";
import { isValidEmail } from "utils/utils";
import { isValidPhoneNumber } from "react-phone-number-input";

const defaultPayload: IUserPayload = {
  email: "",
  firstname: "",
  lastname: "",
  phone: "",
  profilePicture: "",
  role: "USER",
  active: 0,
};

function useUsersHooks({ onOperationSuccess = () => {} }) {
  const [loading, setLoading] = useState(false);
  const { setUserList, setFilteredUsers } = useContext(UserContext);
  const [userPayload, setUserPayload] = useState<IUserPayload>(defaultPayload);
  const [messageError, setMessageError] = useState("");
  const [isValidPayload, setIsValidPayload] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleting, setDeleting] = useState(false);

  const performGetUserList = (page = 1) => {
    setLoading(true);
    getUsersList(page)
      .then((response) => {
        const data = response.data?.results as IUserItem[];
        const { total_records, current_page, total_pages } =
          response.data?.pagination;
        setUserList(data);
        setFilteredUsers(data);
        setTotalPage(total_pages);
        setLoading(false);
      })
      .catch((e) => {
        // toast.error("Error while retrieving users list");
        console.log("error ", e);
        setLoading(false);
      });
  };

  const performCreateUser = () => {
    setMessageError("");
    setLoading(true);
    if (
      !userPayload.profilePicture ||
      userPayload?.profilePicture?.length === 0
    ) {
      delete userPayload["profilePicture"];
    }
    createUser(userPayload)
      .then(async (response) => {
        const data = response.data;
        console.log("create user response ", data);
        toast.success("User created successfully !");
        onOperationSuccess?.();
        setLoading(false);
        await performGetUserList();
      })
      .catch((e) => {
        const error = e as AxiosError;
        const errorDetail = error.response?.data as IErrorResponse;
        let errorMessage = "";
        if (errorDetail) {
          if (errorDetail.error_code === "USER_ALREADY_EXISTS") {
            errorMessage = `User already exist ! please check the field : ${errorDetail.detail}`;
          } else {
            //errorMessage = JSON.stringify(errorDetail);
          }
        } else {
          errorMessage = "Error while create user";
        }
        toast.error(errorMessage);
        setMessageError(errorMessage);
        console.log("error ", e);

        setLoading(false);
      });
  };

  const performUpdateUser = () => {
    if (!userPayload) {
      return;
    }

    if (
      !userPayload.profilePicture ||
      userPayload?.profilePicture?.length === 0
    ) {
      delete userPayload["profilePicture"];
    }

    setMessageError("");
    setLoading(true);
    updateUser(userPayload)
      .then(async (response) => {
        const data = response.data;
        console.log("update user response ", data);
        toast.success("User updated successfully !");
        onOperationSuccess?.();
        setLoading(false);
        await performGetUserList();
      })
      .catch((e) => {
        const error = e as AxiosError;
        const errorDetail = error.response?.data as IErrorResponse;
        let errorMessage = "";
        if (errorDetail) {
          if (errorDetail.error_code === "USER_ALREADY_EXISTS") {
            errorMessage = `User already exist ! please check the field : ${errorDetail.detail}`;
          } else {
            errorMessage = "Error 500 while update user";
          }
        } else {
          errorMessage = "Error while update user";
        }
        toast.error(errorMessage);
        //setMessageError(errorMessage);
        console.log("error ", e);

        setLoading(false);
      });
  };

  const DeleteUser = (userId: number) => {
    if (userId > 0) {
      setDeleting(true);
      UserService.DeleteUser({ userId })
        .then(async (res) => {
          setDeleting(false);
          toast.success("Suppression avec succès");
          onOperationSuccess?.();
          await performGetUserList();
        })
        .catch((e) => {
          toast.error("Problème de suppression");
          console.log("error ", e);
          setDeleting(false);
        });
    } else {
      toast.error("Utilisateur non renseigné");
    }
  };

  useEffect(() => {
    console.log("userPayload is", userPayload);
    setIsValidPayload(
      userPayload.email.length > 0 &&
        isValidEmail(userPayload.email) &&
        userPayload.firstname.length >= 3 &&
        userPayload.lastname.length >= 3 &&
        userPayload?.phone?.length > 0 &&
        isValidPhoneNumber(userPayload.phone)
    );
  }, [userPayload]);

  useEffect(() => {}, [currentPage]);

  return {
    performGetUserList,
    loading,
    setUserList,
    userPayload,
    setUserPayload,
    performCreateUser,
    isValidPayload,
    performUpdateUser,
    messageError,
    setCurrentPage,
    totalPage,
    currentPage,

    DeleteUser,
    deleting,
  };
}

export default useUsersHooks;
