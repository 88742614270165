import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import { CommissionIcon } from "components/Icons/CommissionIcon";
import { CommissionIconActive } from "components/Icons/CommissionIconActive";
import { DashboardActiveIcon } from "components/Icons/DashboardActiveIcon";
import { DashboardIcon } from "components/Icons/DashboardIcon";
import { JournalIconActive } from "components/Icons/JouranlIconActive";
import { JournalIcon } from "components/Icons/JournalIcon";
import ManageIcon from "components/Icons/ManageIcon";
import { OperateurIcon } from "components/Icons/OperateurIcon";
import { OperatorIconActive } from "components/Icons/OperatorIconActive";
import { TransactionIcon } from "components/Icons/TransactionIcon";
import { TransactionIconActive } from "components/Icons/TransactionIconActive";
import { UserIcon } from "components/Icons/UserIcon";
import { UserIconActive } from "components/Icons/UserIconActive";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { ROUTES } from "routes/routes";
import { useScreenType } from "utils/constants/UseScreenType";
import { baseStyles } from "utils/constants/baseStyles";

const SideBarMenu = () => {
  const { pathname } = useLocation();
  const { isTab } = useScreenType();
  const { t } = useTranslation();
  const menuItems = [
    {
      id: 1,
      name: t('menu.dashboard'),
      pathname: ROUTES.dashboard,
      icon: <DashboardIcon />,
      activeIcon: <DashboardIcon color={baseStyles.color.white} />,
    },
    {
      id: 2,
      name: t('menu.users'),
      pathname: ROUTES.users,
      icon: <UserIcon />,
      activeIcon: <UserIcon color={baseStyles.color.white} />,
    },
    {
      id: 3,
      name: t('menu.transactions'),
      pathname: ROUTES.transactions,
      icon: <TransactionIcon />,
      activeIcon: <TransactionIcon color={baseStyles.color.white} />,
    },
    {
      id: 4,
      name: t('menu.journal'),
      pathname: ROUTES.journal,
      icon: <JournalIcon />,
      activeIcon: <JournalIcon color={baseStyles.color.white} />,
    },
    {
      id: 4,
      name: t('menu.countryManagement'),
      pathname: ROUTES.country,
      icon: <ManageIcon />,
      activeIcon: <ManageIcon color={baseStyles.color.white} />,
    },
    {
      id: 5,
      name: t('menu.commissions'),
      pathname: ROUTES.commissions,
      icon: <CommissionIcon />,
      activeIcon: <CommissionIcon color={baseStyles.color.white} />,
    },
    {
      id: 6,
      name: t('menu.operators'),
      pathname: ROUTES.operators,
      icon: <OperateurIcon />,
      activeIcon: <OperateurIcon color={baseStyles.color.white} />,
    },
  ];

  function isActive(itemPathName: string) {
    if (
      pathname === ROUTES.manageCommision ||
      pathname === ROUTES.commissions
    ) {
      return itemPathName === ROUTES.commissions;
    }

    // Sinon, utilise simplement la comparaison normale
    return pathname?.includes(itemPathName);
  }
  return (
    <nav style={{ display: "flex", flexDirection: "column" }}>
      {menuItems?.map((menuItem) => {
        const isActiveMenu = isActive(menuItem.pathname);
        return (
          <NavLink
            to={menuItem.pathname}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: 45,
              justifyContent: "space-between",
              marginBottom: 20,
              textDecoration: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {isActiveMenu ? (
                <NeomorphContainer
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 10,
                    background: isActiveMenu
                      ? baseStyles.color.blue.primary
                      : baseStyles.color.grey,
                  }}
                  width={45}
                  height={45}
                >
                  <span style={{ display: "flex" }}>{menuItem.activeIcon}</span>
                </NeomorphContainer>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 45,
                    height: 45,
                  }}
                >
                  <span style={{ display: "flex" }}>
                    {isActiveMenu ? menuItem.activeIcon : menuItem.icon}
                  </span>
                </div>
              )}
              <span
                style={{
                  marginLeft: 20,
                  marginTop: -2,
                  color: isActiveMenu
                    ? baseStyles.color.blue.primary
                    : baseStyles.text.default,
                  fontSize: 14,
                  fontWeight: 700,
                }}
              >
                {menuItem.name}
              </span>
            </div>

            {!isTab && (
              <span
                style={{
                  height: "100%",
                  width: 5,
                  borderTopLeftRadius: 5,
                  borderBottomLeftRadius: 5,
                  backgroundColor: isActiveMenu
                    ? baseStyles.color.blue.primary
                    : "transparent",
                }}
              />
            )}
          </NavLink>
        );
      })}
    </nav>
  );
};

export default SideBarMenu;
